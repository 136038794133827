@import "@globals/grid"
@import "@globals/breakpoints"

.Footer__root
  padding-top: 2rem
  width: 100%
  flex-basis: 100%
  @include grid-container

  @media print
    display: none


.Footer__text
  position: relative

  &:before
    @media #{$breakpoint-m}
      content: ""
      height: 100%
      display: block
      border-right: 1px solid black
      position: absolute
      left: -0.5rem


.Footer__item_logo img
  width: 100%
  height: auto

.Footer__item
  margin-bottom: 2.2rem
  @include grid(1, 4)

  @media #{$breakpoint-m}
    @include grid(1, 2, $grid-default-m)

  @media #{$breakpoint-l}
    @include grid(1, 2, $grid-default-l)

  @media #{$breakpoint-xl}
    @include grid(1, 3, $grid-default-xl)

  &:first-child
    @include grid(1, 2)

    @media #{$breakpoint-m}
      @include grid(1, 2, $grid-default-m)

    @media #{$breakpoint-l}
      @include grid(1, 3, $grid-default-l, false, 2)

    @media #{$breakpoint-xl}
      @include grid(1, 3, $grid-default-xl, false, 4)
      margin-left: 0

.Footer__title
  margin-bottom: 0.2rem
